import React, { useContext, useEffect,useState } from "react";
import { FaListAlt } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Select from "react-select";
import { requestPost } from "../set-apis";
import { Http } from "../http-dragon";
import { api } from "../url";
import { selectCreater } from "../utils/custom-button";
import { dateWithTime } from "../utils/date-formater";
import { OrderContext } from "../loginData/RealTimeOrderProvider";
import CurrentOrder from "../components/current-order";
import { toast } from "react-toastify";
import OrderDetails from "../components/order-details";
import No_Order from "../components/no-order";


const OrderHistory = () => {
  const { realTimeOrder } = useContext(OrderContext);
  const [itemList,setItemList] = useState({user:null,restaurant:null,items:null})
  const [orderDetailPopup, setOrderDetailPopup] = useState(false);
  const [name, setName] = useState("");
  const [restaurantsList, setRestaurantsList] = useState([]);
  const [restaurantValue, setRestaurantValue] = useState("");
  const [diningOrderList, setDiningOrderList] = useState([]);
  const [takeAwayOrderList, setTakeAwayOrderList] = useState([]);
  const [preOrderList, setPreOrderList] = useState([]);
  const [inCommingOrder,setInCommingOrder] =useState({})
  const [isInCommingOrder,setIsInCommingOrder] =useState(false)
  const [loginData,setLoginData] = useState(null)

  useEffect(() => {
    let data =  JSON.parse(localStorage.getItem("user"))
    setLoginData(data)
    givePermit(data)

  }, [name]);

  useEffect(()=>{
    if(realTimeOrder){
      toast.info("Get New Order.")

      switch(realTimeOrder.orderType) {
        case "TAKE_AWAY":
            setTakeAwayOrderList([realTimeOrder, ...takeAwayOrderList])
            break;
        case "DINING":
            setDiningOrderList([realTimeOrder, ...diningOrderList])
        case "PRE_ORDER":
            setPreOrderList([realTimeOrder, ...preOrderList]) 
      }
      
    }
  },[realTimeOrder])
 
const givePermit =(data)=>{
  if(data?.role==="SUPER_ADMIN"){
    if (name) {
      const clear = setTimeout(() => {
        fetchRestaurant();
      }, 500);
      return () => clearTimeout(clear);
    }
  }else if(data?.role==="OWNER"){
    selectCreater(data?.restaurant);
    setRestaurantsList(data?.restaurant);
  }else if(data?.role==="MANAGER"){

    fetchOrdersPreOrder(data?.restaurant[0]?._id);
    fetchOrdersTakAway(data?.restaurant[0]?._id);
    fetchOrdersDining(data?.restaurant[0]?._id);
  }
}
  const fetchRestaurant = async () => {
    Http.loader(true);
    const res = await requestPost(api.getRestaurantsByName, { search: name });
    Http.loader(false);
    console.log("res.data", res.data);

    if (!res.data) {
      setRestaurantsList([]);
    } else {
      selectCreater(res.data?.data);
      setRestaurantsList(res.data?.data);
    }
  };

  const fetchOrdersDining = async (restaurantID) => {
    const res = await requestPost(api.findOrdersHistoryByOrderType, {
      restaurantID,
      orderType: "DINING",
    });
    if (!res.data) {
      setDiningOrderList([]);
    } else {
      setDiningOrderList(res.data?.data);
    }
  };
  const fetchOrdersPreOrder = async (restaurantID) => {
    const res = await requestPost(api.findOrdersHistoryByOrderType, {
      restaurantID,
      orderType: "PRE_ORDER",
    });
    if (!res.data) {
      setPreOrderList([]);
    } else {
      setPreOrderList(res.data?.data);
    }
  };
  const fetchOrdersTakAway = async (restaurantID) => {
    const res = await requestPost(api.findOrdersHistoryByOrderType, {
      restaurantID,
      orderType: "TAKE_AWAY",
    });
    if (!res.data) {
      setTakeAwayOrderList([]);
    } else {
      setTakeAwayOrderList(res.data?.data);
    }
  };

  const dropdownChangeHandler = (e) => {
    setRestaurantValue(e);
    fetchOrdersPreOrder(e.value);
    fetchOrdersTakAway(e.value);
    fetchOrdersDining(e.value);
  };
  const handleItemList = (item)=>{
    setOrderDetailPopup(true)
    setItemList(item)
  }

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaListAlt size="20" />
                        </span>
                      </span>
                      Order history
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="mt-5 restaurant-edit">
            {loginData?.role==="SUPER_ADMIN" || loginData?.role==="OWNER" ? <div className="row justify-content-center">
              <div className="col-12 col-md-4 ">
                <label htmlFor="applicant">
                  <b>Search Restaurant</b>
                </label>
                <Select
                  onInputChange={(e) => setName(e)}
                  inputValue={name}
                  options={restaurantsList}
                  value={restaurantValue}
                  onChange={dropdownChangeHandler}
                />
              </div>
            </div>:"" }
            
            <div className="row">
              <Tabs className="mt-5 sipstpswp">
                <div className="row justify-content-center ">
                  <div className="col-12 col-md-7">
                    <TabList>
                      <Tab>Pre-order</Tab>
                      <Tab>Dining</Tab>
                      <Tab>Takeaway</Tab>
                    </TabList>
                  </div>
                </div>
                <div className="my-5">
                  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                  <th>Order Time</th>
                                    <th>User Name</th>
                                    <th>Restaurant Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Start time</th>
                                    <th>Party Size</th>
                                    <th>Payment Method</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {preOrderList.length > 0 ? (
                                    preOrderList.map((item) => {
                                      return (
                                        <tr>
                                           <td>{dateWithTime(item.updatedAt)} </td>
                                          <td>{item.user.name} </td>
                                          <td>{item.restaurant.name} </td>
                                          <td> {item.finalAmount?.toFixed(2)}</td>
                                          <td>{item.cartTotal?.toFixed(2)}</td>
                                          <td>{item.taxAndCharges?.toFixed(2)}</td>
                                          <td></td>
                                          <td>{item.discountedAmount?.toFixed(2)}</td>
                                          <td>
                                            {dateWithTime(item.startTime)}
                                          </td>
                                          <td>{item.partySize}</td>
                                          <td>{item.paymentMethod}</td>
                                          <td className={item.orderStatus==="completed"? "text-success":"text-danger"}>
                                           {item.orderStatus}
                                          </td>
                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={()=>handleItemList(item)}
                                            >
                                        
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="text-center">
                                    <td colSpan="12"><No_Order/></td>
                                   </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Order Time</th>
                                    <th>User Name</th>
                                    <th>Restaurant Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Table no</th>
                                    <th>Payment Method</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {diningOrderList.length > 0 ? (
                                    diningOrderList.map((item) => {
                                      return (
                                        <tr>
                                           <td>{dateWithTime(item.updatedAt)} </td>
                                          <td>{item.user.name} </td>
                                          <td>{item.restaurant.name} </td>

                                          <td> {item.finalAmount?.toFixed(2)}</td>
                                          <td>{item.cartTotal?.toFixed(2)}</td>
                                          <td>{item.taxAndCharges?.toFixed(2)}</td>
                                          <td></td>
                                          <td>{item.discountedAmount?.toFixed(2)}</td>
                                          <td>
                                            {item.tableNo ? item.tableNo : "NA"}
                                          </td>
                                          <td>{item.paymentMethod}</td>
                                          <td className={item.orderStatus==="completed"? "text-success":"text-danger"}>
                                           {item.orderStatus}
                                          </td>
                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={()=>handleItemList(item)}
                                            >
                                            
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="text-center">
                                    <td colSpan="12"><No_Order/></td>
                                   </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Order Time</th>
                                    <th>User Name</th>
                                    <th>Restaurant Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Payment Method</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {takeAwayOrderList.length > 0 ? (
                                    takeAwayOrderList.map((item) => {
                                      return (
                                        <tr>
                                           <td>{dateWithTime(item.updatedAt)} </td>
                                          <td>{item.user.name} </td>
                                          <td>{item.restaurant.name} </td>
                                          <td>₹ {item.finalAmount?.toFixed(2)}</td>
                                          <td>₹ {item.cartTotal?.toFixed(2)}</td>
                                          <td>₹ {item.taxAndCharges?.toFixed(2)}</td>
                                          <td></td>
                                          <td>₹ {item.discountedAmount?.toFixed(2)}</td>
                                          <td className={item.orderStatus==="completed"? "text-success":"text-danger"}>
                                           {item.orderStatus}
                                          </td>
                                          <td>{item.paymentMethod}</td>
                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={()=>handleItemList(item)}
                                            >
                                              {" "}
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr className="text-center">
                                    <td colSpan="12"><No_Order/></td>
                                   </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </section>
        </div>

     
    

        {/* items details end*/}
        <OrderDetails show={orderDetailPopup} setShow={setOrderDetailPopup} itemList={itemList}/>

      </main>
      <CurrentOrder show={isInCommingOrder} setShow={setIsInCommingOrder} data={inCommingOrder}/>
    </>
  );
};

export default OrderHistory;
