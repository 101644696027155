import React, { useContext, useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { OrderContext } from "../loginData/RealTimeOrderProvider";
import FullImage from "../components/full-image";
import profileimg from "../assets/img/businessman.png";

const Notifications = () => {
  const { waiterNotification, setWaiterNotification } =
    useContext(OrderContext);
  const [isShowImage, setIsShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const deleteNotification = (index) => {
    waiterNotification.splice(index, 1);
    localStorage.setItem("notifications", JSON.stringify(waiterNotification));
    setWaiterNotification([...waiterNotification]);
  };

  const showImage = (url) => {
    setImageUrl(url);
    setIsShowImage(true);
  };

  useEffect(() => {
    const noti = JSON.parse(localStorage.getItem("notifications"));
    if (noti) setWaiterNotification(noti);
  }, []);

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaBell size="20" />
                        </span>
                      </span>
                      Notifications
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="mt-5 restaurant-edit">
            <div className="row">
              <div className="my-5">
                <section className="upload-recm ">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-md-8 bg-white p-lg-3 p-2 table-shadow mb-5">
                        <div className="shadowc p-1  bg-hero">
                          <table className="table text-center">
                            <thead>
                              <tr>
                                <th>SR.</th>
                                <time>Time</time>
                                <th>Name</th>
                                <th>Phone No</th>
                                <th>Email</th>
                                <th>Table No.</th>
                                <th>Order No.</th>
                                <th>Image</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {waiterNotification.map((notification, i) => {
                                return (
                                  <tr className="bg-white" key={i}>
                                    <td>{i + 1}</td>
                                    <td>{notification.callTime}</td>
                                    <td>{notification.user.name}</td>
                                    <td>{notification.user.phoneNo}</td>
                                    <td>{notification.user.email}</td>
                                    <td>{notification.tableNo}</td>
                                    <td>{notification.orderNumber}</td>
                                    <td
                                      onClick={() =>
                                        showImage(
                                          notification.user.userImage.url
                                        )
                                      }
                                      className="crPointer"
                                    >
                                      <img
                                        src={notification?.user?.userImage?.url|| profileimg}
                                        width={50}
                                        height={50}
                                      />
                                    </td>
                                    <td onClick={() => deleteNotification(i)}>
                                      <ImCross className="cross fs-5" />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </main>
      <FullImage show={isShowImage} setShow={setIsShowImage} data={imageUrl} />
    </>
  );
};

export default Notifications;
