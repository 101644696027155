import { React, useEffect, useState } from "react";
import {  FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { api, baseUrl } from "../url";
import { toast } from "react-toastify";
import { Http } from "../http-dragon";

const SendNotifications = () => {
  const [tittle, setTittle] = useState("");
  const [body, setBody] = useState("");

  const onSave = () => {
    if (!tittle?.trim()) {
      return toast.error("Title is required...");
    }
    if (!body) {
      return toast.error("Body is  required...");
    }


    
    Http.loader(true);
    const reqBody = {
      title:tittle.trim(),
      body:body.trim()
    }
    axios
      .post(baseUrl + api.sendNotifications, reqBody)
      .then((res) => {
        Http.loader(false);
        setTittle("");
        toast.success(res.data?.message)
        setBody("");
      })
      .catch((error) => {
        Http.loader(false);
        console.log(error);
        toast.error (error.response.data?.message)
      });
  };

 
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaUserPlus size="20" />
                        </span>
                      </span>
                      Send Notification to all user
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>
              <form>
                <div className="row  mt-4 shadow-search">
                  <div className="col-4 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Tittle</b>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={tittle}
                      onChange={(e) => setTittle(e.target.value)}
                    />
                  </div>

                  <div className="col-4 col-md-4">
                    <label htmlFor="applicant">
                      <b>Body</b>
                    </label>
                    <input
                      type="text"
                      name="idProof"
                      accept="image/*"
                      className="form-control"
                    value={body}
                      onChange={(e) => setBody(e.target.value)}
                    />
                  </div>
                  <div className="col-4 col-md-4  mt-4  text-center">
                    <button
                      type="button"
                    
                      onClick={onSave}
                      className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                    >
                      save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>

        </div>
      </main>
    </>
  );
};
export default SendNotifications;
