import React from 'react';
import Modal from 'react-bootstrap/Modal';

function FullImage({show,setShow,data}) {


  return (
    <>
   
      <Modal show={show}  onHide={() => setShow(false)} size='md'>
        <Modal.Header closeButton>
          <Modal.Title>Proof</Modal.Title>
        </Modal.Header>
        <Modal.Body><img src={data} alt='no image' className='img-fluid'/></Modal.Body>
      </Modal>
    </>
  );
}

export default FullImage;