import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { dateWithTime } from "../utils/date-formater";
import NewOrderDish from "./NewOrderDish";

function NewOrder({ show, setShow, data }) {
  const [isNewDishAvailable,setIsNewDishAvailable] =useState(false)
  const [newDishAvailableList,setNewDishAvailableList] =useState([])

  const showNewOrderDish =(data)=>{
    setIsNewDishAvailable(true)
    // setShow(false)
    setNewDishAvailableList(data)
  }
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?.user?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S. no.</th>
                <th scope="col">Order Time</th>
                <th scope="col">Dish</th>
              </tr>
            </thead>
            <tbody>
              {data.subOrders?.length > 0 && data.subOrders.map((item,i)=>{
                return   <tr>
                <th scope="row">{i+1}</th>
                <td>{dateWithTime(item.updatedAt)}</td>
                <td onClick={()=>showNewOrderDish(item?.items)}><button type="button" class="btn btn-success">Show added dish</button> </td>
                
              </tr>
              })}
            
             
            </tbody>
          </table>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Ok</Button>
      </Modal.Footer>
      <NewOrderDish
        show={isNewDishAvailable}
        setShow={setIsNewDishAvailable}
        data={newDishAvailableList}
      />
    </Modal>
  );
}

export default NewOrder;
