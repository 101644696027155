import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FiLogOut, FiUser } from "react-icons/fi";
import { CiSettings } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { requestGet, requestPost } from "../set-apis";
import { api } from "../url";
import { errorToast } from "../pages/logics/utils";
const Profile_Dropdown = () => {
  const navigate = useNavigate();
  const logOut = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.role === "MANAGER") {
      requestGet(api.logoutManager)
        .then((res) => {
          localStorage.clear();
          navigate("/login");
        })
        .catch((err) => {
          errorToast(err);
        });
    } else {
      localStorage.clear();
      navigate("/login");
    }
  };
  return (
    <>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <Dropdown.Item href="#/action-3">
          {" "}
          <Link
            className="dropdown-item d-flex align-items-center"
            to="/user-profile"
          >
            <FiUser className="me-3 text-bluec fs-19" />
            <span>My Profile</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item href="#/action-3" className="border-top">
          <Link
            className="dropdown-item d-flex align-items-center"
            to="/user-profile"
          >
            <CiSettings className="me-3 text-bluec fs-19" />
            <span>Account Settings</span>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item className="border-top">
          {" "}
          <div
            className="dropdown-item d-flex align-items-center"
            onClick={logOut}
          >
            <FiLogOut className="me-3 text-bluec fs-19" />
            <span>
              <div className="text-black">Sign Out</div>
            </span>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </>
  );
};

export default Profile_Dropdown;
