import { React, useEffect, useState } from "react";
import { FaTrash, FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { api, baseUrl } from "../url";
import { toast } from "react-toastify";
import { Http } from "../http-dragon";
import { errorToast, successToast } from "./logics/utils";
import { BiCategory } from "react-icons/bi";
import { dateEasyFormat } from "../utils/date-formater";
import { requestPost } from "../set-apis";

const RevenueManage = () => {
  const [name, setName] = useState("");
  const [ownerList, setOwnerList] = useState([]);
  const [ownerValue, setOwnerValue] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [restaurantList, setRestaurantList] = useState([]);
  const [ownerName, setOwnerName] = useState({});
  const [restaurantName, setRestaurantName] = useState({});
  const [restaurantValue, setRestaurantValue] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    fetchOwner();
  }, []);

  const fetchOwner = async () => {
    Http.loader(true);
    axios
      .get(baseUrl + api.getOwners)
      .then((res) => {
        Http.loader(false);
        setOwnerList(res.data.owners);
      })
      .catch((error) => {
        Http.loader(false);
        setOwnerList([]);
        toast.error(error.response.data.msg);
      });
  };

  const onSave = () => {
    if (
      !ownerValue.trim() ||
      !restaurantValue.trim() ||
      !transactionId.trim() ||
      !transactionDate.trim()||
      !amount.trim()
    ) {
      return toast.error("All field required..");
    }
    const reqBody = {
      ownerName: ownerName?.name,
      owner: ownerName?._id,
      restaurantName: restaurantName?.name,
      restaurant: restaurantName?._id,
      transactionDate,
      transactionId,
      amount,
    };
    Http.loader(true);
    axios
      .post(baseUrl + api.savePaymentProof, reqBody)
      .then((res) => {
        Http.loader(false);
        setTransactionDate("");
        setTransactionId("");
        fetchTransactionList(restaurantName?._id)
        successToast(res);
      })
      .catch((error) => {
        Http.loader(false);
        console.log(error);
        errorToast(error);
      });
  };

  const handleOwner = (e) => {
    let value = e.target.value;
    if (value) {
      setOwnerValue(e.target.value);
      let parsedValue = JSON.parse(value);
      setOwnerName(parsedValue);
      fetchRestautantList(parsedValue?._id);
    }
  };
  const handleRestaurant = (e) => {
    let value = e.target.value;
    if (value) {
      setRestaurantValue(e.target.value);
      let parsedValue = JSON.parse(value);
      setRestaurantName(parsedValue);
      fetchTransactionList(parsedValue?._id);
    }
  };
  const amountHandler = (e) => {
    let value = e.target.value;
    if (!isNaN(value)) {
      setAmount(value);
    }
  };
  const fetchTransactionList = (restaurantId) => {
    Http.loader(true);
    axios
      .post(baseUrl + api.getPaymentsByRestaurant, { restaurantId })
      .then((res) => {
        setTransactionList(res.data?.data);
        Http.loader(false);
      })
      .catch((error) => {
        Http.loader(false);
        setTransactionList([]);

        toast.error(error.response.data.message);
      });
  };

  const fetchRestautantList = (ownerID) => {
    Http.loader(true);
    axios
      .post(baseUrl + api.getRestaurantByOwner, { ownerID })
      .then((res) => {
        setRestaurantList(res.data?.restaurants);
        Http.loader(false);
      })
      .catch((error) => {
        Http.loader(false);
        setRestaurantList([]);

        toast.error(error.response.data.message);
      });
  };
  const deleteTransaction =async (paymentId) => {
    Http.loader(true);
   const res = await requestPost(api.deletePayementProof,{paymentId})
   if(res.data){
    successToast(res)
    Http.loader(false);
    fetchTransactionList(restaurantName?._id);
   }else{
    Http.loader(false);
    errorToast(res)
   }
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiCategory size="20" />
                        </span>
                      </span>
                      Restautant Bills
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>
              <form>
                <div className="row  mt-4 shadow-search">
                  <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="applicant">
                      <b>Select Owner</b>
                    </label>
                    <select
                      name="orderStatus"
                      id="cars"
                      className="form-select"
                      value={ownerValue}
                      onChange={handleOwner}
                    >
                      <option value="">Select Owner...</option>
                      {ownerList?.map((item) => {
                        return (
                          <option value={JSON.stringify(item)}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="applicant">
                      <b>Select Restaurant</b>
                    </label>
                    <select
                      name="orderStatus"
                      id="cars"
                      className="form-select"
                      value={restaurantValue}
                      onChange={handleRestaurant}
                    >
                      <option value="">Select Restaurant...</option>
                      {restaurantList?.map((item) => {
                        return (
                          <option value={JSON.stringify(item)}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Tranaction ID</b>
                    </label>
                    <input
                      type="text"
                      name="transactionId"
                      className="form-control"
                      value={transactionId}
                      onChange={(e) => setTransactionId(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Transaction Date</b>
                    </label>
                    <input
                      type="date"
                      name="transactionDate"
                      className="form-control"
                      value={transactionDate}
                      onChange={(e) => setTransactionDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Amount</b>
                    </label>
                    <input
                      type="text"
                      name="transactionDate"
                      className="form-control"
                      value={amount}
                      onChange={amountHandler}
                    />
                  </div>

                  <div className="col-12 col-md-4  mt-4  ">
                    <button
                      type="button"
                      onClick={onSave}
                      className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                    >
                      save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Date</th>
                    <th>Payment Date</th>
                    <th>Trnsaction Id</th>
                    <th>Owner Name</th>
                    <th>Restaurant Name</th>
                    <th>Amount</th>
                    <th>Delete</th>
                  </tr>
                  {transactionList?.map((el, i) => {
                    return (
                      <tr key={el.name}>
                        <td>{i + 1}</td>
                        <td>{dateEasyFormat(el.createdAt)}</td>
                        <td>{dateEasyFormat(el.transactionDate)}</td>
                        <td>{el.transactionId}</td>
                        <td>{el.ownerName}</td>
                        <td>{el.restaurantName}</td>
                        <td>{el.amount}</td>
                        <td
                          className="text-danger crPointer"
                          onClick={() => deleteTransaction(el._id)}
                        >
                          <FaTrash size={20} className="p-0" />
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default RevenueManage;
