import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { dateEasyFormat } from "../utils/date-formater";
import html2pdf from 'html2pdf.js'
import { Http } from "../http-dragon";
const OrderDetails = ({ show, setShow, itemList }) => {
  function downloadPdf(elementId, filename) {
  
        const content = document.getElementById(elementId);
        Http.loader(true)
        html2pdf(content, {
            margin: 0.5,
            filename:filename,
            image: { type: 'jpeg', quality: 0.9 },
            html2canvas: { scale: 3},
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        }).then(()=>{
            Http.loader(false)
        })
    }

  return (
    <Modal show={show} onHide={() => setShow(false)} centered >
      <Modal.Header closeButton>
        <Modal.Title>{itemList?.restaurant?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-md-12" id="printablediv">
          <div className="row">
            <div className="row">
              <div className="receipt-right">
                <h5>Name : {itemList?.user?.name}</h5>
                <p>
                  <b>Mobile :</b>{" "}
                  {itemList?.user?.phoneNo && itemList?.user?.phoneNo}
                </p>
                <p>
                  <b>Email :</b> {itemList?.user?.email}
                </p>
                <p>
                  <b>OrderId :</b> {itemList?.orderNumber}
                </p>
                <p>
                  <b>Payment method :</b> {itemList?.paymentMethod}
                </p>
                <p>
                  <b>Date:</b> {dateEasyFormat(itemList?.preOrderDate||itemList?.createdAt )}
                </p>
              </div>
            </div>

            <div>
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Dish Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total </th>
                  </tr>
                </thead>
                <tbody>
                  {itemList?.items?.map((item, index) => {
                    return (
                      <tr>
                        <td className="col-md-3">{index + 1}</td>
                        <td className="col-md-6">{item.dishName}</td>
                        <td className="col-md-3">{item.quantity}</td>
                        <td className="col-md-3">{item.price}</td>
                        <td className="col-md-3">
                          <i className="fa fa-inr"></i>
                          {item.totalPrice}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td></td>
                    <td className="text-right">
                      <p>
                        <strong>Total Amount: </strong>
                      </p>
                      {itemList?.discountedAmount > 0 ? (
                        <p>
                          <strong>Discounted: </strong>
                        </p>
                      ) : (
                        ""
                      )}
                      <p>
                        <strong>Taxes: </strong>
                      </p>
                    </td>
                    <td colSpan={3}>
                      <p>
                        <strong>
                          <i className="fa fa-inr"></i>
                          {itemList.cartTotal?.toFixed(2)}/-
                        </strong>
                      </p>
                      {itemList?.discountedAmount > 0 ? (
                        <p>
                          <strong>
                            <i className="fa fa-inr"></i>{" "}
                            {itemList?.discountedAmount?.toFixed(2)}
                          </strong>
                        </p>
                      ) : (
                        ""
                      )}

                      <p>
                        <strong>
                          <i className="fa fa-inr"></i>{" "}
                          {itemList?.taxAndCharges?.toFixed(2)}/-
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="text-right">
                      <h4>
                        <strong>Total: </strong>
                      </h4>
                    </td>
                    <td className="text-left text-danger" colSpan={3}>
                      <h4>
                        <strong>
                          <i className="fa fa-inr"></i>{" "}
                          {itemList?.finalAmount?.toFixed(2)}/-
                        </strong>
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <div className="row">
              <div className="receipt-header receipt-header-mid receipt-footer">
                <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                  <div className="receipt-right">
                    <p>
                      <b>Date :</b> 15 Aug 2016
                    </p>
                    <h5>Thanks for shopping.!</h5>
                  </div>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-4">
                  <div className="receipt-left">
                    <h1>Stamp</h1>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={()=>downloadPdf("printablediv",itemList?.user?.name)}>
          Print
        </Button>
        <Button variant="danger" onClick={() => setShow(false)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderDetails;
