import { React, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { api, baseUrl } from "../url";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import { http, Http } from "../http-dragon";
import { errorToast } from "./logics/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { requestPost } from "../set-apis";
import DishRevenue from "../components/dish-revenue";
import HighestLikeUser from "../components/highest-like-user";
import LossUser from "../components/lossUser";
import { dateWithTime, getCurrentDate, getLast30DaysDate } from "../utils/date-formater";


const Revenue = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const preOrderCharge = 0.15
  const diningCharge = 0.03
  const [startDate, setStartDate] = useState(getLast30DaysDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [orderList, setOrderList] = useState([]);
  const [orderType, setOrderType] = useState("all");
  const [dishesPopUP, setDishesPopUP] = useState(false);
  const [dishList,setDishList] = useState([])
  const [highestUserPopUp,setHighestUserPopUp] = useState(false)
  const [userDishList,setUserDishList] = useState([])
  const [lossUserList,setLossUserList] = useState([])
  const [lossUserpopUp,setLossUserPopUp] = useState(false)
  const [totalData,setTotalData] = useState({})
  const [totalPlatformFees,setTotalPlatformFees] = useState(0)


  useEffect(() => {
    const reqBody = {
      startDate:getLast30DaysDate(),
      endDate:getCurrentDate(),
      restaurantId: location.state?._id,
      orderType,
    };
    fetchOrderList(reqBody);
  }, []);

  const fetchOrderList = (reqBody) => {
   
    Http.loader(true);
    axios
      .post(baseUrl + api.getRevenueByRestaurant, reqBody)
      .then((res) => {
        Http.loader(false);
        setTotalData(res.data?.data)
        setOrderList(res.data?.data?.orders);
        addDate(res.data?.data?.orders)
        platformFeeCalculate(res.data?.data?.orders)
        platformFeeForOrder(res.data?.data?.orders)
      })
      .catch((err) => {
        setOrderList([]);
        http.loader(false);
        errorToast(err);
      });
  };
  const addDate = (array) => {
    array.forEach(element => {
      element.date = dateWithTime(element.createdAt)
          });
  }
  const platformFeeCalculate =(arr)=>{
   let fees = 0
       for(let i=0; i<arr.length;i++){
        if(arr[i].orderType==="PRE_ORDER" ||arr[i].orderType==="TAKE_AWAY" ){
          fees  =fees+ (arr[i].finalAmount*preOrderCharge)
        }else if(arr[i].orderType==="DINING" && arr[i].paymentMethod==="online"){
          fees  = fees+ (arr[i].finalAmount*diningCharge)
        }
       }
       console.log("fees",fees);
       setTotalPlatformFees(fees)
       
  }
  const platformFeeForOrder =(arr)=>{
        arr.forEach((item)=>{
          item.platformFee = (item?.orderType==="DINING" && item?.paymentMethod==="online") ?(item?.finalAmount*diningCharge)?.toFixed(2) : item?.orderType==="PRE_ORDER" || item?.orderType==="TAKE_AWAY"  ? (item?.cartTotal*preOrderCharge)?.toFixed(2):0
        
  })}

  const onSave = () => {
    if (!startDate?.trim() || !endDate || !orderType) {
      return toast.error("All fields required.");
    }
    const reqBody = {
      startDate,
      endDate,
      restaurantId: location.state?._id,
      orderType,
    };
    fetchOrderList(reqBody);
  };
  const orderTypeHandler = (e) => {
    if (e.target.value) {
      setOrderType(e.target.value);
    }
  };

  const showDishes =async ()=>{
    Http.loader(true)
    const reqBody = {
      startDate,
      endDate,
      restaurantId: location.state?._id,
      orderType,
    };
    const res= await  requestPost(api.itemsRevenue,reqBody)
    if(res.data){
      setDishesPopUP(true)
      Http.loader(false)
      setDishList(res.data.data)
    }else{
      Http.loader(false)
      setDishList([])
    }
  }

  const showUser =async ()=>{
    Http.loader(true)
    const reqBody = {
      startDate,
      endDate,
      restaurantId: location.state?._id,
      orderType,
    };
    const res= await  requestPost(api.lifeTimeValue,reqBody)
    if(res.data){
      Http.loader(false)
      setHighestUserPopUp(true)
      setUserDishList(res.data.data)
    }else{
      Http.loader(false)
      setUserDishList([])
    }
  }
  const lossUser =async ()=>{
    Http.loader(true)
    const reqBody = {
      startDate,
      endDate,
      restaurantId: location.state?._id,
      orderType,
    };
    const res= await  requestPost(api.lostRevenue,reqBody)
    if(res.data){
      Http.loader(false)
      setLossUserPopUp(true)
      setLossUserList(res.data.data)
    }else{
      Http.loader(false)
      setLossUserList([])
    }
  }

  const data = {
    columns: [
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order ID",
        field: "orderNumber",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order type",
        field: "orderType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment Method",
        field: "paymentMethod",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "user",
        sort: "asc",
        width: 150,
      },
      {
        label: "Cart Total",
        field: "cartTotal",
        sort: "asc",
        width: 150,
      },
      {
        label: "Taxes",
        field: "taxAndCharges",
        sort: "asc",
        width: 150,
      },
      {
        label: "Platform Fee",
        field: "platformFee",
        sort: "asc",
        width: 150,
      },
      {
        label: "Discount",
        field: "discountedAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Final Amount",
        field: "finalAmount",
        sort: "asc",
        width: 200,
      },
    ],
    rows: orderList,
  };

 const showTransactions = ()=>{
    navigate("/transactions", { state: totalData?.paymentsRecords });
  
 }

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaUserPlus size="20" />
                        </span>
                      </span>
                      Rvenue <h4 className="text-center text-purple">{location?.state?.name}</h4>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>
              <form>
                <div className="row  mt-4 shadow-search">
                  <div className="col-4 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Start date</b>
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  <div className="col-4 col-md-4">
                    <label htmlFor="applicant">
                      <b>End date</b>
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={endDate}
                      className="form-control"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-4 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Order Type</b>
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={orderType}
                      onChange={orderTypeHandler}
                    >
                      <option value="">Select...</option>
                      <option value="DINING">Dine-in</option>
                      <option value="PRE_ORDER">Pre-Order</option>
                      <option value="TAKE_AWAY">Take-Away</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                  <div className="col-12  mt-4  text-end">
                    <button
                      type="button"
                      disabled={!startDate.trim() || !endDate}
                      onClick={onSave}
                      className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                    >
                      Check detail
                    </button>
                  </div>
                </div>
              </form>
            </div>
          
          </section>
          {orderList?.length> 0&&<>
            <div className="row justify-content-end my-4">
              <div className="col-lg-5 col-md-12 ">
              <div className="d-flex justify-content-between">
                  <button className="btn btn-success" onClick={showDishes} >
                    Trending Dishes 
                  </button>
                  <button className="btn btn-success" onClick={showUser} >
                    Important User
                  </button>
                  <button className="btn btn-danger" onClick={lossUser} >
                    Lost User
                  </button>
              </div>
              </div>
            </div>
          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr className="bg-light">
                    <th>Order Count</th>
                    <th>Total Revenue</th>
                    <th>Cart Total(A)</th>
                    <th>Total Tax(B)</th>
                    <th>Platform fees(C)</th>
                    <th>(D.) Net Receivable Amt(A+B-C)</th>
                    <th>Total Cash(E)</th>
                    <th>Transferable Amt.(D-E)</th>
                    <th>Total Online</th>
                    <th>Total discount</th>
                    <th>Trxn.</th>
                  </tr>
                      <tr >
                        <td>{totalData?.count}</td>
                        <td>{totalData?.totalRevenue?.toLocaleString('en-IN')}</td>
                        <td>{totalData?.totalCartValue?.toLocaleString('en-IN')}</td>
                        <td>{totalData?.totalTax?.toLocaleString('en-IN')}</td>
                        <td>-{totalPlatformFees?.toLocaleString('en-IN')}</td>
                        <td>{(totalData?.totalCartValue+totalData?.totalTax-totalPlatformFees)?.toLocaleString('en-IN')}</td>
                        <td>{totalData?.cashAmount?.toLocaleString('en-IN')}</td>
                        <td>{((totalData?.totalCartValue+totalData?.totalTax-totalPlatformFees)-totalData?.cashAmount)?.toLocaleString('en-IN')}</td>
                        <td>{totalData?.onlineAmount?.toLocaleString('en-IN')}</td>
                        <td>{totalData?.totalDiscount?.toLocaleString('en-IN')}</td>
                       <td onClick={showTransactions}>    <button className="bg-success text-light rounded" >
                    Show
                  </button></td>
                      </tr>
                  
                </table>
              </div>
            </div>
          </section></>
}
          <section className="px-4">
            <div className="row  mt-4 mb-5 shadow-search">
              <MDBDataTable data={data} responsive />
            </div>
          </section>
        </div>
      </main>
      <DishRevenue show={dishesPopUP} setShow={setDishesPopUP} data={dishList}/>
      <HighestLikeUser show={highestUserPopUp} setShow={setHighestUserPopUp} data={userDishList}/>
      <LossUser show={lossUserpopUp} setShow={setLossUserPopUp} data={lossUserList}/>

    </>
  );
};
export default Revenue;
