import { React, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { api, baseUrl } from "../url";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import { http, Http } from "../http-dragon";
import { errorToast } from "./logics/utils";
import { useLocation } from "react-router-dom";
import { dateWithTime, getCurrentDate, getLast30DaysDate } from "../utils/date-formater";

const AppRevenue = () => {
  const location = useLocation();
  const [startDate, setStartDate] = useState(getLast30DaysDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [orderList, setOrderList] = useState([]);
  const [orderType, setOrderType] = useState("PRE_ORDER");
  const [tempOrderType, setTempOrderType] = useState("PRE_ORDER");

  const [totalData,setTotalData] = useState({})


  useEffect(() => {
    const reqBody = {
      startDate:getLast30DaysDate(),
      endDate:getCurrentDate(),
      restaurantId: location.state?._id,
      orderType:"PRE_ORDER",
    };
    fetchOrderList(reqBody);
  }, []);

  const fetchOrderList = (reqBody) => {
   
    Http.loader(true);
    axios
      .post(baseUrl + api.getRevenueByRestaurant, reqBody)
      .then((res) => {
        Http.loader(false);
        setTotalData(res.data?.data)
        setOrderList(res.data?.data?.orders);
        addKeys(res.data?.data?.orders)
      })
      .catch((err) => {
        setOrderList([]);
        http.loader(false);
        errorToast(err);
      });
  };

  const onSave = () => {
    if (!startDate?.trim() || !endDate || !orderType) {
      return toast.error("All fields required.");
    }
    const reqBody = {
      startDate,
      endDate,
      restaurantId: location.state?._id,
      orderType,
    };
    fetchOrderList(reqBody);
  };
  const orderTypeHandler = (e) => {
    if (e.target.value) {
      setOrderType(e.target.value);
    }
  };

  const addKeys = (array)=>{
       array.forEach(item=>{
           item.percentage = (item?.orderType==="DINING") ?(item?.finalAmount*0.03)?.toFixed(2) : (item?.cartTotal*0.15)?.toFixed(2)
           item.finalAmount2 = item.finalAmount?.toFixed(2)
       })
       setTempOrderType(orderType)
  }

  const data = {
    columns: [
      {
        label: "Name",
        field: "user",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "cartTotal",
        sort: "asc",
        width: 150,
      },
      {
        label: "Taxes",
        field: "taxAndCharges",
        sort: "asc",
        width: 150,
      },
      {
        label: "Discount",
        field: "discountedAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order ID",
        field: "orderNumber",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Value",
        field: "finalAmount2",
        sort: "asc",
        width: 200,
      },
      {
        label: "App revenue",
        field: "percentage",
        sort: "asc",
        width: 200,
      },
    ],
    rows: orderList,
  };

 

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaUserPlus size="20" />
                        </span>
                      </span>
                     App Revenue
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>
              <form>
                <div className="row  mt-4 shadow-search">
                  <div className="col-4 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Start date</b>
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  <div className="col-4 col-md-4">
                    <label htmlFor="applicant">
                      <b>End date</b>
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={endDate}
                      className="form-control"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-4 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Order Type</b>
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={orderType}
                      onChange={orderTypeHandler}
                    >
                      <option value="">Select...</option>
                      <option value="DINING">Dine-in</option>
                      <option value="PRE_ORDER">Pre-Order</option>
                      <option value="TAKE_AWAY">Take-Away</option>
                      {/* <option value="all">All</option> */}
                    </select>
                  </div>
                  <div className="col-12  mt-4  text-end">
                    <button
                      type="button"
                      disabled={!startDate.trim() || !endDate}
                      onClick={onSave}
                      className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                    >
                      save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          
          </section>
          {orderList?.length> 0&&<>
          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                 
                  <tr className="bg-light">
                    <th>Order Time</th>
                    <th>Total Count</th>
                    <th>Total Tax</th>
                    <th>Total discount</th>
                    <th>Total order value</th>
                    <th>Total Revenue</th>
                  </tr>
                
                      <tr >
                      <td>{dateWithTime(totalData?.updatedAt)} </td>
                        <td>{totalData?.count}</td>
                        <td>{totalData?.totalTax?.toFixed(2)}</td>
                        <td>{totalData?.totalDiscount?.toFixed(2)}</td>
                        <td>{totalData?.totalRevenue?.toFixed(2)}</td>
                        <td>{ tempOrderType==="DINING" ? (totalData?.totalRevenue*0.03)?.toFixed(2) :(totalData?.totalCartValue*0.15)?.toFixed(2)}</td>
                     
                       
                      </tr>
                  
                </table>
              </div>
            </div>
          </section></>
}
          <section className="px-4 ">
            <div className="row  mt-4 mb-5 shadow-search">
              <MDBDataTable data={data} responsive />
            </div>
          </section>
        </div>
      </main>


    </>
  );
};
export default AppRevenue;
