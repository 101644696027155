import { React, useState, useEffect } from 'react';
import { FaArrowCircleRight, FaTrash } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { MDBDataTable } from 'mdbreact';
import Select from 'react-select';
import {  useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { selectCreater } from '../utils/custom-button';
import { api, baseUrl } from '../url';
import { Http } from '../http-dragon';
import { requestPost } from '../set-apis';
import { errorToast, successToast } from './logics/utils';
const Sub_Category = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [subcategoryName, setSubCategoryName] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [categoryID, setCategoryID] = useState("")
    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    useEffect(() => {
        if(location?.state?._id){
            fetchCategory()
            // fetchSubCategory()
        }else{
            navigate("/restaurant-lists")
        }
       
    }, [])
    const fetchCategory = () => {
        Http.loader(true)
            axios.post(baseUrl + api.getCategories, { restaurantID: location.state._id }).then(res => {
                setCategoryList(res.data?.categories)
                Http.loader(false)
                // addButton(res.data?.categories)
                selectCreater(res.data?.categories)
            }).catch ((error)=> {
            setCategoryList([])
            Http.loader(false)
            toast.error(error.response?.data?.message)
        })
    }
    const fetchSubCategory = (e) => {
        setCategoryID(e)
        Http.loader(true)
            axios.post(baseUrl + api.getSubCategories, { categoryID: e?.value }).then(res => {
                setSubCategoryList(res.data?.data)
                addButton(res.data?.data)
                Http.loader(false)
            }).catch ((error)=> {
            setCategoryList([])
            Http.loader(false)
            toast.error(error.response?.data?.message)
        })
        Http.loader(false)
    }

    const addButton = (array) => {
        array.forEach(element => {
            element.delete = <div className="btn btn-danger pt-0 pb-1"><FaTrash /></div>
        });
    }
    const addSubCategory = () => {
        console.log("categoryName", location.state);
        if (!categoryName) {
            toast.error("Enter category name..!")
            return
        }
        if (!subcategoryName) {
            toast.error("Enter sub ctegory name..!")
            return
        }
        Http.loader(true)
        try {
            console.log("success");
            axios.post(baseUrl + "create-sub-category", { subCategory: subcategoryName, categoryID: categoryName.value }).then(res => {
                toast.success(res.data?.message)
                setCategoryName("")
                Http.loader(false)
                setSubCategoryName("")
            })

        } catch (error) {
            console.log("errror");
            Http.loader(false)
            toast.error(error.response?.data?.message)
        }
        Http.loader(false)
    }
    const gotoMenuSection = () => {
        navigate("/menu", { state: { _id: location.state._id } })

    }
 const deleteSubCategories =async (subCategoryID)=>{
    Http.loader(true)
     const res = await requestPost(api.deleteSubCategory,{subCategoryID})
     if(res.data){
        Http.loader(false)
        fetchSubCategory(categoryID)
        successToast(res)
     }else{
        Http.loader(false)
        errorToast(res)
     }
 }
    return (
        <>
            <main id="main">
                <div className="content-wrapper">
                    <div className="page-nav">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="page-header ms-2">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <span>
                                                    <BiCategory size='20' />

                                                </span>
                                            </span>
                                            Sub Category
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='my-5 Category'>
                        <div className="row justify-content-center">
                            <div className="col-md-8 shadowc bg-white py-3 rounded">
                                <div className="row text-center">
                                    <div className="col-5">
                                        <label> <b>Category <span className='text-danger'>*</span></b></label>
                                        <Select
                                            options={categoryList}
                                            onChange={setCategoryName}
                                            value={categoryName}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <label><b>Sub Category</b></label>
                                        <input type='text' className='form-control' value={subcategoryName} onChange={(e) => setSubCategoryName(e.target.value)} />
                                    </div>
                                    <div className="col-2 align-self-center pt-4 pe-2" onClick={addSubCategory}>
                                        <button class="bg-gradient-primary text-white p-2 custom-button  ">Add +</button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </section>
                    <div className="row text-center">
                    <div className="row justify-content-end">
                            <div className="col-4 text-end mb-3" onClick={gotoMenuSection}>
                                <div class="sub-btn" >Add Menus<FaArrowCircleRight /> </div>
                            </div>
                            
                            <label > <b>Select Category for show Sub Category List <span className='text-danger'>*</span></b></label>
                            <Select
                            className='mb-3'
                                options={categoryList}
                                value={categoryID}
                                onChange={fetchSubCategory}
                            />
                        </div>
                    </div>
                    <section className='px-4'>

                        <div className="row">
                            <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                            <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Sub Category</th>
                    <th>Delete</th>
                  </tr>
                  {subCategoryList?.map((el, i) => {
                    return (
                      <tr key={i}>
                        <th>{i + 1}.</th>
                        <td>{el.name}</td>
                        <td
                          className="text-danger crPointer"
                          onClick={() => deleteSubCategories(el._id)}
                        >
                          <FaTrash size={20} className="p-0" />
                        </td>
                      </tr>
                    );
                  })}
                </table>

                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
export default Sub_Category;