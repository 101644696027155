import React, { useState, useEffect } from "react";
import "../assets/css/bell.css"; // Add the CSS below into this file
import bellIcon from "../assets/css/bell-icon.svg";
import Rolex from "../assets/css/bell.wav";
import { useContext } from "react";
import { OrderContext } from "../loginData/RealTimeOrderProvider";
import { NavLink } from "react-router-dom";

const NotificationBell = () => {
  const [showDot, setShowDot] = useState(false); // State to show/hide notification dot
  const [animate, setAnimate] = useState(false); // State for bell animation
  const { orderNotifier, setOrderNotifier } = useContext(OrderContext);

  // Function to trigger the notification
  const triggerNotification = () => {
    setOrderNotifier(false);
    setShowDot(true); // Show notification dot
    setAnimate(true); // Start animation

    // Play sound
    const audio = new Audio(Rolex); // Replace with your sound file path
    console.log(audio.src);
    audio.play();

    // Stop animation after 0.5 seconds
    setTimeout(() => {
      setAnimate(false);
    }, 500);
  };

  useEffect(() => {
    if (orderNotifier) {
      triggerNotification();
    }
  }, [orderNotifier]);

  return (
    <>
      <NavLink
        to="/orders"
        activeClassName="nav-link"
        className="d-flex nav-link-c"
        onClick={() => setShowDot(false)}
      >
        <div className="notification-container">
          <button
            className={`notification-bell ${animate ? "bell-animate" : ""}`}
            // Hide dot on click
          >
            <img
              src={bellIcon} // Replace with your bell icon path
              alt="Notification Bell"
              className="bell-icon"
            />
            {showDot && <span className="notification-dot"></span>}
            <span className="px-2">Orders</span>
          </button>
        </div>
      </NavLink>
    </>
  );
};

export default NotificationBell;
