import { React, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import axios from "axios";
import { baseUrl } from "../url";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Http } from "../http-dragon";

const EditAddGlobalCategoriesTwo = () => {
  const [globalCategoriesTwo, setGlobalCategoriesTwo] = useState([]);
  const [newAddedCategories, setNewAddedCategories] = useState([]);

  const { state } = useLocation();

  useEffect(() => {
    Http.loader(true)
    axios.get(baseUrl + "get-global-categories-two").then((res) => {
      setGlobalCategoriesTwo(res.data.data);
      // Http.loader(false)
    });

    axios
      .post(baseUrl + "get-global-categories-two-of-restaurant", {
        restaurantID: state._id,
      })
      .then((res) => {
        Http.loader(false)
        setNewAddedCategories(res.data.data);
      });
      Http.loader(false)
  }, []);

  const checkBoxHandler = (e) => {
    const value = e.target.value;

    if (newAddedCategories.includes(value)) {
      const index = newAddedCategories.indexOf(value);
      newAddedCategories.splice(index, 1);
    } else {
      newAddedCategories.push(value);
    }

    setNewAddedCategories([...newAddedCategories]);
  };

  const updateCatgories = () => {
    Http.loader(true);
    axios
      .post(baseUrl + "add-or-update-global-category-two", {
        restaurantID: state._id,
        categoryIDs: newAddedCategories,
      })
      .then((res) => {
        Http.loader(false);
        toast.success(res.data.message);
      }).catch(err => {
        Http.loader(false)
        toast.error(err.response.data.message);
      })
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaUserPlus size="20" />
                        </span>
                      </span>
                      Edit Add  Restaurant Categories
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>check</th>
                  </tr>
                  {globalCategoriesTwo.map((el, i) => {
                    return (
                      <tr key={el.name}>
                        <th>{i + 1}</th>
                        <th>{el.name}</th>
                        <td>
                          <img
                            width={50}
                            height={50}
                            src={el.url}
                            className="img-fluid"
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={newAddedCategories.includes(el._id)}
                            onChange={checkBoxHandler}
                            value={el._id}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <button className="btn btn-primary" onClick={updateCatgories}>
                  Save
                </button>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default EditAddGlobalCategoriesTwo;
