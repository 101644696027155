import { React, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { validateField } from "../utils/custom-validations";
import { toast } from "react-toastify";
import axios from "axios";
import { api, baseUrl } from "../url";
import { useLocation, useNavigate } from "react-router-dom";
import { Http } from "../http-dragon";
import { requestGet } from "../set-apis";
const Owner_Details = () => {
  const initialValue = {
    name: "",
    phoneno: "",
    pan: "",
    adhaar: "",
    idProof: "",
    email: "",
    password: "",
    bankName: "",
    ifscCode: "",
    accountNumber: "",
    branchCode: "",
    accountHolderName: "",
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValue);
  const [formError, setFormError] = useState(initialValue);
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    if (location?.state) {
      setFormData(location.state);
    }
    fetchBankName();
  }, []);

  const fetchBankName = async () => {
    const res = await requestGet(api.getBankList);
    if (res.data) {
      console.log("==", res.data.data);

      setBankList(res.data.data);
    } else {
      setBankList([]);
    }
  };
  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    if (name === "idProof") {
      setFormData({
        ...formData,
        [name]: e.target.files[0],
      });
    } else if (name === "pan" && value.length < 11) {
      setFormData({
        ...formData,
        [name]: value.toUpperCase().trim(),
      });
    } else if (name === "adhaar" && value.length < 13) {
      setFormData({
        ...formData,
        [name]: value.trim(),
      });
    } else if (name === "phoneno" && value.length < 11 && !isNaN(value)) {
      setFormData({
        ...formData,
        [name]: value.trim(),
      });
    } else if (
      ((name === "email" || name === "name" || name === "accountHolderName") &&
        value.length < 150) ||
      name == "password"
    ) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "ifscCode" && value.length < 12) {
      setFormData({
        ...formData,
        [name]: value.toUpperCase(),
      });
    } else if (name === "bankName") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "branchCode" && value.length < 20) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (name === "accountNumber" && !isNaN(value) && value.length < 20) {
      setFormData({
        ...formData,
        [name]: value.trim(),
      });
    }
  };

  const ownerSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    let idProof = formData.idProof;
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (location.state && key === "idProof") {
        idProof = "";
      } else {
        if (error) {
          newErrors[key] = error;
        }
      }
    });
    if (Object.keys(newErrors).length === 0) {
      let formBody = new FormData();
      formBody.append("name", formData.name);
      formBody.append("phoneno", formData.phoneno);
      formBody.append("email", formData.email);
      formBody.append("adhaar", formData.adhaar);
      formBody.append("pan", formData.pan);
      formBody.append("idProof", idProof);
      formBody.append("password", formData.password);
      formBody.append("bankName", formData.bankName);
      formBody.append("branchCode", formData.branchCode);
      formBody.append("ifscCode", formData.ifscCode);
      formBody.append("accountNumber", formData.accountNumber);
      formBody.append("accountHolderName", formData.accountHolderName);
      Http.loader(true);
      try {
        if (location?.state?.id) {
          formBody.append("id", location?.state?.id);
          const res = await axios.post(baseUrl + api.updateOwner, formBody);
          toast.success(res.data.message);
          Http.loader(false);
          setFormData(initialValue);
          setFormError(initialValue);
        } else {
          const res = await axios.post(baseUrl + api.createOwner, formBody);
          toast.success(res.data.message);
          Http.loader(false);
          setFormData(initialValue);
          navigate("/restaurant-details", {
            state: { name: res.data?.data?.name, ownerId: res.data?.data?._id },
          });
          setFormError(initialValue);
        }
      } catch (error) {
        Http.loader(false);
        toast.error(error.response?.data?.message);
        setFormError(initialValue);
      }
    } else {
      Http.loader(false);
      setFormError(newErrors);
    }
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaUserPlus size="20" />
                        </span>
                      </span>
                      Owner Details
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>
              <form>
                <div className="row  my-5 shadow-search">
                  <div className="col-12 col-md-4 ">
                    <label htmlFor="applicant">
                      <b>Name</b>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.name}
                    />
                    <small className="text-danger">{formError?.name}</small>
                  </div>
                  <div className="col-12 col-md-4 mt-lg-0 mt-3">
                    <label htmlFor="applicant">
                      <b>Mobile Number</b>
                    </label>
                    <input
                      type="text"
                      name="phoneno"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.phoneno}
                    />
                    <small className="text-danger">{formError?.phoneno}</small>
                  </div>
                  <div className="col-12 col-md-4 mt-lg-0 mt-3">
                    <label htmlFor="applicant">
                      <b>Pan Card</b>
                    </label>
                    <input
                      type="text"
                      name="pan"
                      value={formData.pan}
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">{formError?.pan}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>Aadhaar Card</b>
                    </label>
                    <input
                      type="text"
                      name="adhaar"
                      value={formData.adhaar}
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">{formError?.adhaar}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>Email Id</b>
                    </label>
                    <input
                      type="text"
                      value={formData.email}
                      name="email"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">{formError?.email}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>Create Password</b>
                    </label>
                    <input
                      type="text"
                      value={formData.password}
                      name="password"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">{formError?.password}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>Proof upload (aadhaar/pan)</b>
                    </label>
                    <input
                      type="file"
                      name="idProof"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">{formError?.idProof}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>Account Holder Name</b>
                    </label>
                    <input
                      type="text"
                      value={formData.accountHolderName}
                      name="accountHolderName"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">
                      {formError?.accountHolderName}
                    </small>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mt-3">
                    <label htmlFor="applicant">
                      <b>Select Bank Name</b>
                    </label>
                    <select
                      name="bankName"
                      id="bankName"
                      className="form-select"
                      value={formData.bankName}
                      onChange={handleChange}
                    >
                      <option value="">Select Bank...</option>
                      {bankList?.length > 0 &&
                        bankList.map((item) => {
                          return (
                            <option value={item.bankName}>
                              {item.bankName}
                            </option>
                          );
                        })}
                    </select>
                    <small className="text-danger">{formError?.bankName}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>IFSC Code</b>
                    </label>
                    <input
                      type="text"
                      value={formData.ifscCode}
                      name="ifscCode"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">{formError?.ifscCode}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>Account Number</b>
                    </label>
                    <input
                      type="text"
                      value={formData.accountNumber}
                      name="accountNumber"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">
                      {formError?.accountNumber}
                    </small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant">
                      <b>Branch Code</b>
                    </label>
                    <input
                      type="text"
                      value={formData.branchCode}
                      name="branchCode"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <small className="text-danger">
                      {formError?.branchCode}
                    </small>
                  </div>
                  <div className="col-12 col-md-4 align-self-center mt-4 pt-3">
                    <button
                      type="button"
                      onClick={ownerSubmit}
                      className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                    >
                      {location?.state?.id ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default Owner_Details;
