import { React, useEffect, useState } from "react";
import { BiCategory, BiDetail } from "react-icons/bi";
import axios from "axios";
import { api, baseUrl } from "../url";
import Select from "react-select";
import { toast } from "react-toastify";
import { Http } from "../http-dragon";

const AddLevel2 = () => {
  const [name, setName] = useState("");
  const [restaurantsList, setRestaurantsList] = useState([]);
  const [restaurantValue, setRestaurantValue] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (name) {
      const clear = setTimeout(() => {
        fetchRestaurant();
      }, 500);
      return () => clearTimeout(clear);
    }
  }, [name]);

  const fetchLevelTwoCategories = () => {
    Http.loader(true)
    try {
      axios.get(baseUrl + api.getLevelTwoCategories).then((res) => {
        setCategories(res.data.data);
        Http.loader(false)
      })
    }
    catch (error) {
      Http.loader(false)
    }
    Http.loader(false)
  };

  const fetchRestaurant = () => {
    Http.loader(true)
    try{
      axios
      .post(baseUrl + api.getRestaurantsByName, { search: name })
      .then((res) => {
        labelAndValueTransformer(res.data.data);
        Http.loader(false)
      });
    }catch(error){
      Http.loader(false)
    }
   
    Http.loader(false)
  };

  const labelAndValueTransformer = (array) => {
    const data = array.map((el) => {
      return {
        label: el.name,
        value: {
          ids: el?.levelTwoCategories ? el.levelTwoCategories : [],
          restaurantID: el._id,
        },
      };
    });
    setRestaurantsList(data);
  };

  const dropdownChangeHandler = (e) => {
    setRestaurantValue(e);
    fetchLevelTwoCategories();
  };

  const checkboxHandler = (e) => {
    const value = e.target.value;
    if (restaurantValue.value.ids.includes(value)) {
      const index = restaurantValue.value.ids.indexOf(value);
      restaurantValue.value.ids.splice(index, 1);
    } else {
      restaurantValue.value.ids.push(value);
    }
    setRestaurantValue({ ...restaurantValue });
  };

  const onSavedChanges = () => {
    Http.loader(true)
   
    axios
      .post(baseUrl + api.addLevelTwoCategoryOfRestaurant, {
        categoryIDs: restaurantValue.value.ids,
        restaurantID: restaurantValue.value.restaurantID,
      })
      .then((res) => {
        Http.loader(false)
        toast.success(res.data.message);
      })
      .catch((err) => {
        Http.loader(false)
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiCategory size="20" />
                        </span>
                      </span>
                      Level 2 Categories
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 ">
            <label htmlFor="inputName">
              <b>Search Restaurant</b>
            </label>

            <Select
              onInputChange={(e) => setName(e)}
              inputValue={name}
              options={restaurantsList}
              value={restaurantValue}
              onChange={dropdownChangeHandler}
            />
          </div>

          {restaurantValue && (
            <section className="px-4 mt-5">
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered">
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                    {categories.map((el) => (
                      <tr>
                        <td>{el.name}</td>
                        <td>
                          <input
                            type="checkbox"
                            value={el._id}
                            checked={restaurantValue.value.ids.includes(el._id)}
                            onChange={checkboxHandler}
                          />
                        </td>
                      </tr>
                    ))}
                  </table>

                  <button
                    className="btn btn-success float-end"
                    onClick={onSavedChanges}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </section>
          )}
        </div>
      </main>
    </>
  );
};
export default AddLevel2;
